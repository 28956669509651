import React, { useState } from 'react';
import { Button } from 'antd';
import ActionDrawer from '../../shared/ActionDrawer';
import { statusList } from '../../../../../utils';
import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';
import { useUserStatus } from '../../../../../uni-comms-api/hooks/authentication';

import { ReactComponent as OnlineIcon } from '../../../assets/online.svg';
import { ReactComponent as OnBreakIcon } from '../../../assets/onBreak.svg';
import { ReactComponent as OfflineIcon } from '../../../assets/offline.svg';
import { colors, fontWeight } from '../../../../../constants';

const StatusIcons = {
  online: <OnlineIcon />,
  on_break: <OnBreakIcon />,
  offline: <OfflineIcon />
};

function SetStatus() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { userStatus, setUserStatus } = useUserStatus();

  const menuItems = React.useMemo(() => {
    return statusList.map(({ title, key, style }) => ({
      label: (
        <LabelWrapper
          onClick={() => {
            setUserStatus(key);
            // Add a slight delay to show user that status has been set
            setTimeout(() => {
              setOpenDrawer(false);
            }, 500);
          }}
        >
          <StatusOptionContent
            dotBackground={style.backgroundColor}
            dotBorder={style.border}
          >
            {StatusIcons[key as keyof typeof StatusIcons]}
            <span>{title}</span>
          </StatusOptionContent>
          {userStatus === key && (
            <CheckOutlined style={{ color: colors.GREEN }} />
          )}
        </LabelWrapper>
      ),
      key
    }));
  }, [userStatus, setUserStatus]);

  const currentStatus = statusList.find(status => status.key === userStatus);

  return (
    <>
      <ActionDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        title="Status"
        menuItems={menuItems}
      />
      <StyledButton
        onClick={() => setOpenDrawer(true)}
        color={(currentStatus?.color as string) || colors.DARK_BLUE}
      >
        <div className="status-wrapper">
          {StatusIcons[userStatus as keyof typeof StatusIcons]}
        </div>
        <span>Status</span>
      </StyledButton>
    </>
  );
}

export default SetStatus;
const StatusOptionContent = styled.span<{
  dotBackground: string;
  dotBorder: string;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(Button)<{ color: string }>`
  color: ${props => props.color};

  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  gap: 1px;

  font-size: 12px;
  font-weight: ${fontWeight.MEDIUM};
  line-height: 16px;

  .status-wrapper {
    border-radius: 50%;
    border: 1px solid ${props => props.color};
    padding: 0px;
    position: relative;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
  }

  span {
    color: ${props => props.color} !important;
  }
`;
