import { decodeSpecialChars } from './decodeSpecialChars';
import { logger } from './logger';

const applicationName = 'AI Studio';

export class AgentNotification {
  constructor() {
    this.icon = '/static/media/lobster-white.7bd385da.png';
    this.permisionGranted = window.Notification?.permission === 'granted';
    this.permisionDenied = window.Notification?.permission === 'denied';
    this.permisionDefault = window.Notification?.permission === 'default';
  }

  joinedChatOption = {
    body: 'Anonymous User: Joined Chat',
    icon: this.icon,
    requireInteraction: true
  };

  messageChatOption = value => {
    return {
      body: `Anonymous User: ${decodeSpecialChars(value)}`,
      icon: this.icon,
      requireInteraction: true
    };
  };

  requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      // Update permission states after request
      this.permisionGranted = permission === 'granted';
      this.permisionDenied = permission === 'denied';
      this.permisionDefault = permission === 'default';
      return permission;
    } catch (error) {
      logger('Permission request failed:', error);
      return 'denied';
    }
  };

  showNotification = async (title, options) => {
    try {
      // Try regular Notification first
      if (window.Notification) {
        const notification = new window.Notification(title, options);

        if (options.data?.url) {
          notification.onclick = () => {
            parent?.focus();
            window?.focus();

            window.location.assign(options.data.url);
            notification.close();
          };
        }

        logger('sent notification via window.Notification');
        return notification;
      }
      // Fall back to ServiceWorker notification if regular Notification fails
      else if ('serviceWorker' in navigator && navigator.serviceWorker.ready) {
        const registration = await navigator.serviceWorker.ready;
        logger('sending notification via service worker');
        return await registration.showNotification(title, options);
      }
    } catch (error) {
      // If regular Notification failed (like on mobile), try ServiceWorker
      if ('serviceWorker' in navigator && !!navigator.serviceWorker.ready) {
        try {
          const registration = await navigator.serviceWorker.ready;
          logger('error with notifications - try sending notification via service worker', error);
          return await registration.showNotification(title, options);
        } catch (swError) {
          logger('Both notification methods failed:', swError);
        }
      } else {
        logger('Both notification methods failed:', error);
      }
    }
  };

  sendJoinedChatNotification = async chat_id => {
    if (!('Notification' in window) && !('serviceWorker' in navigator)) {
      return;
    }

    // Check if permission is denied
    if (this.permisionDenied) {
      return;
    }

    // Request permission if needed and show notification
    const notif = await this.showNotification(applicationName, {
      ...this.joinedChatOption,
      tag: `message-${chat_id}`,
      renotify: true,
      data: {
        url: `/live-chat/${chat_id}?scrollToBottom=true`,
        baseUrl: window.location.origin
      }
    });
    return notif;
  };

  sendNewMessageNotification = async (value, chatId, clientId) => {
    if (!('Notification' in window) && !('serviceWorker' in navigator)) {
      return;
    }

    // Check if permission is denied
    if (this.permisionDenied) {
      return;
    }

    // Request permission if needed and show notification
    const notif = await this.showNotification(applicationName, {
      ...this.messageChatOption(value),
      tag: `message-${chatId}`,
      renotify: true,
      data: {
        url: `/live-chat/${chatId}`,
        baseUrl: window.location.origin,
        searchParams: `?scrollToBottom=true&clientId=${clientId}`
      }
    });
    // try {
    //   Navigator.vibrate([200]);
    // } catch (error) {
    //   // no action required
    // }
    return notif;
  };
}
