import React from 'react';
import {
  Drawer as BaseDrawer,
  DrawerProps,
  Button,
  Menu,
  MenuProps,
  Spin
} from 'antd';
import styled, { css } from 'styled-components';
import { colors, fontWeight } from '../../../../constants';
import {
  ItemType,
  MenuItemGroupType,
  SubMenuType
} from 'antd/lib/menu/hooks/useItems';
import { LeftOutlined } from '@ant-design/icons';

interface ActionDrawerProps {
  hideCloseIcon?: boolean;
  showCancel?: boolean;
  menuItems?: MenuProps['items'];
  loading?: boolean;
}

export default function ActionDrawer({
  hideCloseIcon = true,
  placement = 'bottom',
  showCancel = true,
  menuItems,
  children,
  loading = false,
  ...drawerProps
}: ActionDrawerProps & DrawerProps) {
  const [currentMenuPath, setCurrentMenuPath] = React.useState<string[]>([]);

  const onTitleClick = React.useCallback(
    (item: ItemType) => {
      if (item) {
        setCurrentMenuPath([
          ...currentMenuPath,
          ...(item.key ? [item.key as string] : [])
        ]);
      }
    },
    [currentMenuPath]
  );

  const { items: currentMenuItems, title } = React.useMemo(() => {
    let currentTitle: DrawerProps['title'] = drawerProps.title;
    let items = (menuItems ?? [])?.map(item => {
      if (item && (item as SubMenuType | MenuItemGroupType).children) {
        (item as SubMenuType).onTitleClick = onTitleClick;
      }
      return item;
    });

    if (currentMenuPath.length > 0) {
      for (const key of currentMenuPath) {
        const currentItem = items?.find(item => item?.key === key) as
          | SubMenuType
          | MenuItemGroupType;

        if (currentItem?.children) {
          items = currentItem.children;
        }

        if (currentItem?.label) {
          currentTitle = currentItem.label;
        }
      }
    }
    return { items, title: currentTitle };
  }, [currentMenuPath, menuItems, onTitleClick, drawerProps.title]);

  const shouldShowBackIcon = currentMenuPath.length > 0;

  return (
    <Drawer
      hideCloseIcon={hideCloseIcon}
      placement={placement}
      extra={
        showCancel ? (
          <ActionButton type="text" onClick={drawerProps.onClose}>
            Cancel
          </ActionButton>
        ) : (
          drawerProps.extra
        )
      }
      {...drawerProps}
      title={
        <TitleContainer>
          {shouldShowBackIcon && (
            <ActionButton
              type="text"
              onClick={() => setCurrentMenuPath([])}
              className="back-button"
            >
              <LeftOutlined />
              Back
            </ActionButton>
          )}
          {title}
        </TitleContainer>
      }
    >
      {loading ? (
        <LoadingContainer>
          <Spin />
        </LoadingContainer>
      ) : (
        !!currentMenuItems?.length && (
          <VerticalMenu
            mode="vertical"
            items={currentMenuItems}
            // To prevent the menu from opening submenus on click
            openKeys={[]}
          />
        )
      )}
      {children}
    </Drawer>
  );
}

const Drawer = styled(BaseDrawer)<ActionDrawerProps>`
  .ant-drawer-body {
    padding: 0;
    margin: 10px 0px;
    height: fit-content;
  }

  .ant-drawer-header {
    position: relative;
  }

  ${({ hideCloseIcon }) =>
    hideCloseIcon &&
    css`
      .ant-drawer-close {
        display: none;
      }
    `}

  .ant-drawer-title {
    text-align: center;
    font-size: 18px;
    font-weight: ${fontWeight.BOLD};
  }

  .ant-drawer-header-title {
    max-width: calc(100% - 110px);
    margin: auto;
  }

  .ant-drawer-content {
    border-radius: 10px 10px 0px 0px;
    height: fit-content;
  }

  // To maintain the ant-drawer-title's center position
  .ant-drawer-extra {
    position: absolute;
    right: 0px;
  }

  .ant-drawer-content-wrapper {
    height: unset !important;
  }
`;

const ActionButton = styled(Button)`
  color: ${colors.RED};
  font-size: 16px;
`;

const VerticalMenu = styled(Menu)`
  font-size: 16px;

  .ant-menu-item:not(:last-child),
  .ant-menu-submenu:not(:last-child) {
    border-bottom: 0.5px solid #00214033;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    margin: 0px 20px;
    padding: 0px;
  }

  .ant-menu-title-content {
    background: transparent !important;
    color: ${colors.DARK_BLUE} !important;
  }

  .ant-menu-item-selected {
    background: transparent !important;
  }

  .ant-menu-submenu-title {
    padding: 0px;
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent !important;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .back-button {
    position: absolute;
    left: 0px;
  }
`;
