import { useState, useEffect } from 'react';
import { SMALL_LAYOUT_THRESHOLD } from './layoutSizes';

interface WindowSize {
  width: number;
  height: number;
}

export const useWindowSize = (): WindowSize & { isMobile: boolean } => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowSize,
    isMobile: windowSize.width < SMALL_LAYOUT_THRESHOLD
  };
};
