import React from 'react';
import MobileRoutes from './MobileRoutes';
import { Layout } from 'antd';
import styled from 'styled-components';

interface MobileViewProps {
  settings: {
    liveChat: {
      liveChatURL: string;
    };
  };
}

export default function MobileView({ settings }: MobileViewProps) {
  return (
    <StyledLayout>
      <MobileRoutes settings={settings} />
    </StyledLayout>
  );
}

const StyledLayout = styled(Layout)`
  height: 100%;

  select,
  textarea,
  input {
    font-size: 16px !important; // To prevent zooming-in on input fields
  }
`;
