import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { colors, fontWeight } from '../../constants';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as TeamIcon } from '../assets/teams.svg';
import { ReactComponent as TeamOutlinedIcon } from '../assets/teams-outlined.svg';
import { ReactComponent as LivechatIcon } from '../assets/livechat.svg';
import { ReactComponent as LivechatOutlinedIcon } from '../assets/livechat-outlined.svg';
import SetStatus from '../../modules/liveChat/modules/chatWindow/inputBar/SetStatus';

const footerItems = [
  {
    label: 'Chat',
    key: '/live-chat',
    icon: <LivechatIcon />,
    outlinedIcon: <LivechatOutlinedIcon />,
    type: 'link'
  },
  {
    label: 'Status',
    key: '/status',
    button: <SetStatus />,
    type: 'button'
  },
  {
    label: 'Teams',
    key: '/teams',
    icon: <TeamIcon />,
    outlinedIcon: <TeamOutlinedIcon />,
    type: 'link'
  }
];

export default function Footer() {
  const { pathname } = useLocation();

  return (
    <FooterWrapper>
      <FooterContainer>
        {footerItems.map(item =>
          item.type === 'link' ? (
            <NavItem
              key={item.key}
              to={item.key}
              $isActive={pathname.includes(item.key)}
            >
              {pathname.includes(item.key) ? item.icon : item.outlinedIcon}
              <NavLabel>{item.label}</NavLabel>
            </NavItem>
          ) : (
            <ButtonWrapper key={item.key}>{item.button}</ButtonWrapper>
          )
        )}
      </FooterContainer>
    </FooterWrapper>
  );
}

const FooterWrapper = styled(Layout.Footer)`
  z-index: 9;
  padding: 0px;
  filter: drop-shadow(0px -1px 6px #0000001a);
  width: 100%;
  min-width: 100vw;
  min-height: 70px;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
`;

const FooterContainer = styled.nav`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
`;

const NavItem = styled(Link)<{ $isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  gap: 2px;
  flex: 1;
  color: ${props => (props.$isActive ? colors.RED : `${colors.DARK_BLUE}`)};
  text-decoration: none;

  svg {
    height: 24px;
  }
`;

const NavLabel = styled.span`
  font-size: 12px;
  font-weight: ${fontWeight.MEDIUM};
  line-height: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  color: ${colors.GREEN};

  .ant-btn {
    border: none !important;
    background-color: transparent !important;
  }
`;
