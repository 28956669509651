import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import {
  ModuleLoader,
  routes,
  useAuthorization,
  useSelectedBot
} from './shell';
import useTags from './uni-comms-api/hooks/useTags';
import { settingsPermission } from './modules/settings/member/permissions';
import { ModalProvider } from './shell';

const Dashboard = React.lazy(() => import('./modules/dashboard'));
const Teams = React.lazy(() => import('./modules/teams'));
const History = React.lazy(() => import('./modules/history'));
const TeamsDefault = React.lazy(() => import('./modules/teams/default'));
const LiveChat = React.lazy(() => import('./modules/liveChat'));
const ChatWindowRoute = React.lazy(() =>
  import('./modules/liveChat/modules/chatWindow/ChatWindowRoute')
);
const MyAi = {
  ResponseStudio: React.lazy(() => import('./modules/myAI/responseStudio')),
  ConversationReview: React.lazy(() =>
    import('./modules/myAI/flows/conversationReview')
  ),
  RequestViewer: React.lazy(() => import('./modules/myAI/flows/requestViewer')),
  AssistantSettings: React.lazy(() => import('./modules/myAI/settings')),
  AISettings: React.lazy(() => import('./modules/myAI/aiSettings'))
};
const Plans = {
  Pricing: React.lazy(() => import('./modules/plans/pricing'))
};

const PaymentInfoAdded = React.lazy(() => import('./modules/paymentInfoAdded'));
const Connector = {
  Connector: React.lazy(() => import('./modules/connector')),
  Oauth: React.lazy(() => import('./modules/connector/Oauth'))
};

const Flows = {
  Root: React.lazy(() => import('./modules/flows')),
  Flows: React.lazy(() => import('./modules/flows/flows/Flows')),
  QRCode: React.lazy(() => import('./modules/flows/flows/modals/QRCodeModal')),
  AddFlow: React.lazy(() => import('./modules/flows/flows/add/CreateNewFlow')),
  CreateFlow: React.lazy(() => import('./modules/flows/flows/add/CreateFlow')),
  CreateSimilarFromFromTemplate: React.lazy(() =>
    import('./modules/flows/flows/add/CreateSimilarFlowFromTemplate')
  ),
  FlowTemplate: React.lazy(() =>
    import('./modules/flows/flows/modals/FlowTemplate')
  ),
  Training: React.lazy(() => import('./modules/flows/flows/training/Training')),
  UniversalContent: React.lazy(() =>
    import('./modules/flows/universalContent/UniversalContent')
  ),
  InformationSourcesContent: React.lazy(() =>
    import(
      './modules/flows/informationSourcesContent/InformationSourcesContent'
    )
  )
};

const Settings = React.lazy(() => import('./modules/settings'));
const Account = {
  Members: React.lazy(() => import('./modules/settings/Members')),
  Member: React.lazy(() => import('./modules/settings/member/Member')),
  MemberAdd: React.lazy(() => import('./modules/settings/MemberAdd')),
  LiveChat: React.lazy(() => import('./modules/settings/LiveChat')),
  Journeys: React.lazy(() => import('./modules/settings/Journeys')),
  Journey: React.lazy(() => import('./modules/settings/journey/Journey')),
  Apps: React.lazy(() => import('./modules/settings/Apps')),
  App: React.lazy(() => import('./modules/settings/app/App'))
};

const settings = {
  bot: {
    url: process.env.BOT_URL
  },
  billing: {
    url: process.env.BILLING_URL
  },
  liveChat: {
    liveChatURL: process.env.AUTH_URL
  }
};

export default function AppRoutes() {
  const { selectedBot, isSelfServe } = useSelectedBot();
  const hasFlows = !!selectedBot.signalProvider;
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <ModuleLoader permission="dashboard:read">
            <Dashboard />
          </ModuleLoader>
        }
      />
      <Route path="/teams">
        <Route
          path="view/:teamId"
          element={
            <ModuleLoader permission="teams:read">
              <Teams />
            </ModuleLoader>
          }
        />
        <Route
          path=""
          element={
            <ModuleLoader permission="teams:read">
              <TeamsDefault />
            </ModuleLoader>
          }
        />
      </Route>
      <Route path="/history">
        <Route
          path=""
          element={
            // TODO change to correct permission
            <ModuleLoader permission="liveChat:read">
              <History />
            </ModuleLoader>
          }
        />
      </Route>
      <Route
        path="/live-chat"
        element={
          <ModuleLoader permission="liveChat:read">
            <LiveChat
              settings={{
                liveChatURL: settings.liveChat.liveChatURL
              }}
            />
          </ModuleLoader>
        }
      >
        <Route
          exact
          path=":chat_id"
          element={
            <ModuleLoader permission="liveChat:read">
              <ChatWindowRoute />
            </ModuleLoader>
          }
        />
      </Route>
      <Route
        path="/flows"
        element={
          <ModuleLoader permission="bots/responseVersions:read">
            <Flows.Root />
          </ModuleLoader>
        }
      >
        <Route path="" element={<Navigate to="flows/initial" />} />
        <Route path="flows" element={<Navigate to="initial" />} />
        <Route
          path="flows/:flowId"
          element={
            <ModuleLoader permission="bots/responseVersions:read">
              <ModalProvider>
                <Flows.Flows />
              </ModalProvider>
            </ModuleLoader>
          }
        >
          <Route
            path="QRCode"
            element={
              <ModuleLoader permission="bots/responseVersions:read">
                <Flows.QRCode />
              </ModuleLoader>
            }
          />
          <Route path="add" element={<Flows.AddFlow />} />
          <Route path="create" element={<Flows.CreateFlow />} />
          <Route
            path="similar"
            element={<Flows.CreateSimilarFromFromTemplate />}
          />
          <Route path="template" element={<Flows.FlowTemplate />} />
          <Route
            path="training/:intentId"
            element={
              <ModuleLoader permission="bots/responseVersions:read">
                <Flows.Training />
              </ModuleLoader>
            }
          />
        </Route>
        <Route
          path="universal-content"
          element={
            <ModuleLoader permission="bots/responseVersions:read">
              <Flows.UniversalContent />
            </ModuleLoader>
          }
        />
        {isSelfServe && (
          <Route
            path="information-sources"
            element={
              <ModuleLoader permission="bots/responseVersions:read">
                <ModalProvider>
                  <Flows.InformationSourcesContent />
                </ModalProvider>
              </ModuleLoader>
            }
          />
        )}
      </Route>
      <Route path="my-ai">
        <Route path="" element={<Navigate to="responses" />} />
        <Route
          path="responses"
          element={
            hasFlows ? (
              <Navigate to="flows" />
            ) : (
              <ModuleLoader permission="bots/responseVersions:read">
                <MyAi.ResponseStudio />
              </ModuleLoader>
            )
          }
        />
        <Route
          path="conversation-review"
          element={
            hasFlows ? (
              <ModuleLoader permission="conversationReview:read">
                <MyAi.ConversationReview />
              </ModuleLoader>
            ) : (
              <Navigate to="responses" />
            )
          }
        />
        <Route
          path="request-viewer/:intentId?"
          element={
            hasFlows ? (
              <ModuleLoader permission="dashboard:read">
                <MyAi.RequestViewer />
              </ModuleLoader>
            ) : (
              <Navigate to="responses" />
            )
          }
        />
        <Route
          path="assistant-settings"
          element={
            <ModuleLoader permission="bots/responseVersions:read">
              <MyAi.AssistantSettings />
            </ModuleLoader>
          }
        />

        <Route
          path="ai-settings"
          element={
            <ModuleLoader permission="bots/responseVersions:read">
              <MyAi.AISettings />
            </ModuleLoader>
          }
        />
      </Route>
      <Route
        path="profile/:memberId"
        element={
          <ModuleLoader permission="settings:read">
            <Account.Member />
          </ModuleLoader>
        }
      />
      <Route
        path="settings"
        element={
          <ModuleLoader permission={settingsPermission.VIEW_SETTINGS}>
            <Settings settings={settings.liveChat} />
          </ModuleLoader>
        }
      >
        <Route
          path="members"
          element={
            <ModuleLoader permission={settingsPermission.VIEW_MEMBERS}>
              <Account.Members />
            </ModuleLoader>
          }
        />
        <Route
          path="members/view/:memberId"
          element={
            <ModuleLoader permission={settingsPermission.VIEW_MEMBER}>
              <Account.Member />
            </ModuleLoader>
          }
        />
        <Route
          path="members/add"
          element={
            <ModuleLoader permission={settingsPermission.ADD_MEMBERS}>
              <Account.MemberAdd />
            </ModuleLoader>
          }
        />
        <Route
          path="livechat"
          element={
            <ModuleLoader permission={settingsPermission.VIEW_LIVECHAT}>
              <Account.LiveChat />
            </ModuleLoader>
          }
        />
        <Route
          path="journeys"
          element={
            <ModuleLoader permission={settingsPermission.VIEW_JOURNEYS}>
              <Account.Journeys />
            </ModuleLoader>
          }
        />
        <Route
          path="journeys/add"
          element={
            <ModuleLoader permission={settingsPermission.ADD_JOURNEYS}>
              <Account.Journeys />
            </ModuleLoader>
          }
        />
        <Route
          path="journeys/view/:pipelineId"
          element={
            <ModuleLoader permission={settingsPermission.VIEW_JOURNEY_PIPELINE}>
              <Account.Journey />
            </ModuleLoader>
          }
        />
        <Route
          path="apps"
          element={
            <ModuleLoader permission={settingsPermission.VIEW_APP}>
              <Account.Apps />
            </ModuleLoader>
          }
        />
        <Route
          path="apps/add"
          element={
            <ModuleLoader permission={settingsPermission.ADD_APPS}>
              <Account.Apps />
            </ModuleLoader>
          }
        />
        <Route
          path="apps/view/:appId"
          element={
            <ModuleLoader permission={settingsPermission.VIEW_APPS}>
              <Account.App />
            </ModuleLoader>
          }
        />
      </Route>
      <Route
        path="plan"
        element={
          <ModuleLoader
            permission="plans:read"
            settings={{
              billingURL: settings.billing.url
            }}
          >
            <Plans.Pricing />
          </ModuleLoader>
        }
      />
      <Route
        path="/paymentInfoAdded"
        element={
          <ModuleLoader permission="billing:read">
            <PaymentInfoAdded />
          </ModuleLoader>
        }
      />
      <Route
        path="/connectors/:connectorId/oauth"
        element={
          <ModuleLoader permission="connectors:read">
            <Connector.Oauth />
          </ModuleLoader>
        }
      />

      <Route path="/403" element={<routes.NotAuthorized />} />
      <Route path="/404" element={<routes.NotFound />} />
      <Route path="/500" element={<routes.ServerError />} />
      <Route path="/408" element={<routes.NoInternet />} />
      <Route path="/" exact element={<DefaultRoute />} />
      <Route path="*" element={<routes.NotFound />} />
    </Routes>
  );
}

function DefaultRoute() {
  const { tagExists } = useTags();

  const canSeeDashboard = useAuthorization('dashboard:read');
  const canSeeResponseStudio = useAuthorization('bots/responseVersions:read');
  const canSeeLivechat = useAuthorization('liveChat:read');

  if (!tagExists('sign-up:initial-login-complete') && canSeeResponseStudio) {
    return <Navigate to="/flows" />;
  } else if (canSeeDashboard) {
    return <Navigate to="/dashboard" />;
  } else if (canSeeLivechat) {
    return <Navigate to="/livechat" />;
  } else {
    return <Navigate to="/settings/profile" />;
  }
}
