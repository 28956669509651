import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { ModuleLoader } from '../shell';
import IconHeader from './IconHeader';
import Footer from './live-chat/Footer';
import { Layout } from 'antd';

const DefaultScreen = React.lazy(() => import('./DefaultScreen'));

const Livechat = {
  Provider: React.lazy(() => import('./live-chat')),
  List: React.lazy(() => import('../modules/liveChat/LiveChatContent')),
  ChatWindow: React.lazy(
    () => import('../modules/liveChat/modules/chatWindow/ChatWindowRoute')
  )
};

const Teams = {
  List: React.lazy(() => import('./teams/index')),
  View: React.lazy(() => import('./teams/TeamView')),
  Settings: React.lazy(() => import('./teams/Settings')),
  Members: React.lazy(() => import('./teams/Members'))
};
interface MobileRoutesProps {
  settings: {
    liveChat: {
      liveChatURL: string;
    };
  };
}

export default function MobileRoutes({ settings }: MobileRoutesProps) {
  return (
    <Routes>
      <Route
        path="/live-chat"
        element={
          <ModuleLoader permission="liveChat:read">
            <Livechat.Provider settings={settings} />
          </ModuleLoader>
        }
      >
        <Route
          path=""
          element={
            <ModuleLoader permission="liveChat:read">
              <IconHeader />
              <Layout.Content style={{ marginBottom: '70px' }}>
                <Livechat.List />
              </Layout.Content>
              <Footer />
            </ModuleLoader>
          }
        />
        <Route
          path=":chat_id"
          element={
            <ModuleLoader permission="liveChat:read">
              <Livechat.ChatWindow />
            </ModuleLoader>
          }
        />
      </Route>
      <Route
        path="/teams"
        element={
          <ModuleLoader permission="teams:read">
            <Outlet />
          </ModuleLoader>
        }
      >
        <Route
          path=""
          element={
            <ModuleLoader permission="teams:read">
              <IconHeader />
              <Teams.List />
              <Footer />
            </ModuleLoader>
          }
        />
        <Route
          path="view/:team_id"
          element={
            <ModuleLoader permission="teams:read">
              <Teams.View />
            </ModuleLoader>
          }
        >
          <Route
            path="settings"
            element={
              <ModuleLoader permission="teams:read">
                <Teams.Settings />
              </ModuleLoader>
            }
          />
          <Route
            path="members"
            element={
              <ModuleLoader permission="teams:read">
                <Teams.Members />
              </ModuleLoader>
            }
          />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <ModuleLoader unauthenticated>
            <IconHeader />
            <DefaultScreen />
          </ModuleLoader>
        }
      />
    </Routes>
  );
}
