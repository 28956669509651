export const statusList = [
  {
    title: 'Online',
    key: 'online',
    style: {
      backgroundColor: '#5FB9AB',
      border: 'solid 2px #5FB9AB'
    },
    color: '#5FB9AB'
  },
  {
    title: 'On break',
    key: 'on_break',
    style: {
      backgroundColor: '#FFC15E',
      border: 'solid 2px #FFC15E'
    },
    color: '#FFC15E'
  },
  {
    title: 'Offline',
    key: 'offline',
    style: {
      backgroundColor: 'transparent',
      border: 'solid 2px #8a8a8a'
    },
    color: '#8a8a8a'
  }
];

export const transformStatus = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).replace(/_/g, ' ');
};

export const getStyle = key => {
  return statusList
    .filter(status => status.key === key)
    .map(currentStatus => currentStatus.style)
    .pop();
};
