import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import logo from './assets/ai-logo-mobile.svg';

import { ReactComponent as LogoutIcon } from './assets/logout.svg';
import { useAuthentication } from '../uni-comms-api/hooks/authentication';

export default function IconHeader() {
  const { logout } = useAuthentication();
  return (
    <HeaderWrapper>
      <img src={logo} alt="" />
      <LogoutButton onClick={logout}>
        <LogoutIcon />
      </LogoutButton>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px 0px;
  box-shadow: 0px 1px 4px #0015291f;
  z-index: 2;
  height: 70px;

  img {
    height: 20px;
  }

  position: relative;
`;

const LogoutButton = styled.div`
  position: absolute;
  right: 16px;
  cursor: pointer;
  display: flex;
`;
